import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_question: { },
    current_scene: { },
  },
  mutations: {
    saveScene(state, cs) {
      state.current_scene = cs
      sessionStorage.setItem('cs', JSON.stringify(state.current_scene))
    },

    saveQ(state, q) {
      state.current_question = q;
      sessionStorage.setItem('cq', JSON.stringify(state.current_question))
    }
  },
  getters: {
    getScene(state) {
      state.current_scene = JSON.parse(sessionStorage.getItem('cs'))
      return state.current_scene
    },

    getQ(state) {
      state.current_question = JSON.parse(sessionStorage.getItem('cq'))
      return state.current_question
    }
  },
  actions: {
    saveScene(context, cs) {
      context.commit('saveScene', cs)
    },

    saveQ(context, q) {
      context.commit('saveQ', q)
    }
  },
  modules: {
  }
})
