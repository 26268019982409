import Vue from 'vue'
import VueRouter from 'vue-router'
import store from'../store'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/portal/Home.vue'),
  },
  {
    path: '/detail',
    name: 'QDetail',
    component: () => import('../views/portal/QDetail.vue'),
  },
  {
    path: '/item',
    name: 'QItem',
    component: () => import('../views/portal/QItem.vue'),
  },
  {
    path: '/order',
    name: 'QPay',
    component: () => import('../views/portal/QPay.vue'),
  },
  /**
  {
    path: '/index',
    name: 'QIndex',
    component: () => import('../views/QIndex.vue'),
  },
  {
    path: '/qpage',
    name: 'QPage',
    component: () => import('../views/QPage.vue'),
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue'),
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue'),
  } //**/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * router guard configurations.
 * @author CAISHIFU
 * @version v1.0
 */
// router.beforeEach((to, from, next) => {
//   if( to.meta.NL ) {
//     if( store.getters.get_admin_user ) {
//       next()
//     } else {
//       if( to.redirectedFrom != '/' )
//       	Message({type: 'error', message: '您尚未登录系统，正在跳转至登录页面...'})
//       router.replace({path: '/login'})
//     }
//   } else {
//     next()
//   }
// })

export default router
